@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 49 10% 94%;
    --foreground: 222.2 84% 4.9%;

    --card: 60 33.3% 98%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 17%;
    --popover-foreground: 0 0% 100%;

    --primary: 0 0% 17%;
    --primary-foreground: 210 40% 98%;

    --secondary: 30 10% 84%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 30 17% 89%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 49 10% 91%;
    --accent-foreground: 222.2 47.4% 11.2%;

    /* --wecopink: 343 98% 63%; */
    --wecopink: 7 100% 64%;
    --wecopink-muted: 11 32% 87%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 30 10% 82%;
    --input: 30 10% 82%;
    --ring: 0 0% 17%;

    --radius: 0.4rem;

    --chart-1: var(--wecopink);
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 27 87% 67%;

    --ring-color: rgba(59, 130, 246, 0.5);
    --ring-offset-color: #f9fafb;
  }

  ::selection {
    background: hsla(7, 100%, 64%, 0.3);
  }

  /* clean up scrollbars (for playground example grid): */
  .slim-scroll::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  .slim-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10rem;
  }

  .slim-scroll::-webkit-scrollbar-track-piece:start,
  .slim-scroll::-webkit-scrollbar-track-piece:end,
  .slim-scroll::-webkit-scrollbar-corner,
  .slim-scroll::-webkit-scrollbar-track {
    background: transparent;
  }

  .invstyle {
    --foreground: 30 17% 90%;
    --border: 0 0% 13%;
    --input: 0 0% 13%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }

  .invstyle {
    @apply text-foreground border-border;
  }
}

code {
  font-family: var(--font-code);
}

/* JSON syntax highlighting styles */
.hljs-attr {
  @apply text-wecopink;
}

.hljs-string {
  @apply text-foreground;
}

.hljs-number {
  @apply text-foreground;
}

.hljs-literal {
  @apply text-foreground;
}




